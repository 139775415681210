import { mdiArrowLeft, mdiCloudUploadOutline, mdiInformationOutline, mdiReload } from '@mdi/js';
import { Icon } from 'its-react-ui';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useRouteMatch } from 'react-router';
import { useHistory } from 'react-router-dom';
import { StringParam, useQueryParam } from 'use-query-params';
import {
  getClaimByClaimNumberAndInsuranceYear,
  getCoverByRiskNumberCoverNumberAndInsuranceYear,
  getReinsuranceContext,
} from '../../apis/insuranceContext';
import { KnownQueryParams, SearchContextType } from '../../containers/queryParameters';
import { fetchAllCaseDocuments as fetchAllCaseDocumentsAction } from '../../containers/Search';
import { reinsuranceDocumentPath } from '../../containers/thunkHelpers';
import { hasRole, hasAnyRole } from '../../containers/User';
import useDialog from '../../hooks/useDialog';
import { RoleCategoryEnum } from '../../lib';
import { getFormatedDate, getFormatedPeriod } from '../../lib/helpers';
import XHR_STATUS from '../../lib/xhrStatus';
import ClaimInfo from '../ClaimInfo';
import * as constants from '../constVariables';
import CoverInfo from '../CoverInfo';
import { ReactComponent as Loader } from '../Icons/loader.svg';
import Search from '../Search';
import DescriptionItem from '../Shared/DescriptionItem';
import IconButton from '../Shared/IconButton';
import ItsDialog, { DialogHeader } from '../Shared/ItsDialog';
import AddFiles from './AddFiles';
import styles from './CaseDocumentSearchBar.module.scss';
import { FeatureEnum, useFeatureFlag } from '../../providers/FeatureFlagProvider';

const CaseDocumentSearchBar = ({ goBack, isMobile = false }) => {
  const dispatch = useDispatch();
  const { caseType } = useParams();
  const { user } = useSelector((state) => ({ user: state.user }));
  const isComplianceOfficer = hasRole(user, RoleCategoryEnum.COMPLIANCE_OFFICER);
  const [searchTextQueryParam, setSearchTextQueryParam] = useQueryParam([KnownQueryParams.SEARCH_TEXT], StringParam);
  const { isShowing, show, hide } = useDialog();
  const [currentContext, setCurrentContext] = useState(null);

  const search = (searchText) => {
    setSearchTextQueryParam(searchText, 'replace');
    dispatch(fetchAllCaseDocumentsAction());
  };

  const reset = () => {
    setSearchTextQueryParam(undefined, 'replace');
    dispatch(fetchAllCaseDocumentsAction());
  };

  const setContext = (context) => {
    setCurrentContext(context);
  };

  return (
    <>
      <div className={styles.searchWrapper}>
        <div className={styles.contextWrapper}>
          {caseType === SearchContextType.CLAIM && <ClaimContext setContext={setContext} />}
          {caseType === SearchContextType.COVER && <CoverContext setContext={setContext} />}
          {caseType === SearchContextType.REINSURANCE && <ReinsuranceContext setContext={setContext} />}
        </div>

        <div className={styles.searchBar}>
          {!isMobile && (
            <button data-test-id="XoZDbW84apWGeUPCnhps5" type="button" className={styles.back} onClick={goBack}>
              <Icon path={mdiArrowLeft} size={constants.ICON_SIZE} color={constants.BLUE_ICON_COLOR} />
            </button>
          )}

          <Search onSearch={search} onReset={reset} hintText="Search" activeSearchText={searchTextQueryParam} hasLeftActions={!isMobile} />
        </div>
      </div>

      {currentContext && hasAnyRole(user) && !isComplianceOfficer && (
        <div className={styles.additionalActions}>
          <IconButton
            data-test-id="vQcEFdpe2VFOeQQvnPoLJ"
            icon={mdiCloudUploadOutline}
            iconSize={constants.SMALL_ICON_SIZE}
            onClick={show}
            text="Add a file"
          />
        </div>
      )}

      <ItsDialog isOpen={isShowing} onDismiss={hide} className={styles.addFilesDialog} aria-label="Add Document">
        <DialogHeader title="Add Document" close={hide} />

        {currentContext && <AddFiles currentContext={currentContext} onSubmit={hide} />}
      </ItsDialog>
    </>
  );
};

CaseDocumentSearchBar.propTypes = {
  goBack: PropTypes.func.isRequired,
  isMobile: PropTypes.bool,
};

export default CaseDocumentSearchBar;

// eslint-disable-next-line react/prop-types
const ReinsuranceContext = ({ setContext }) => {
  const { companyNumber, businessAccountNumber, insuranceYear } = useRouteMatch(reinsuranceDocumentPath).params;
  const getReinsurance = useCallback(
    () => getReinsuranceContext(companyNumber, businessAccountNumber, insuranceYear),
    [businessAccountNumber, companyNumber, insuranceYear]
  );

  const { getInsuranceContext, contentState, data, error } = useInsuranceContext(getReinsurance);

  const retry = () => {
    getInsuranceContext();
  };

  useEffect(() => {
    setContext(data);
  }, [data, setContext]);

  switch (contentState) {
    case XHR_STATUS.ERROR:
      return (
        <div className={styles.error}>
          {error}
          <IconButton
            data-test-id="yllRyY_eaMSu4vksDJIun"
            className={styles.action}
            icon={mdiReload}
            text="Retry"
            iconSize={constants.SMALL_ICON_SIZE}
            onClick={retry}
          />
        </div>
      );
    case XHR_STATUS.SUCCESS:
      return (
        <dl className={styles.context}>
          <DescriptionItem className={styles.item} term="Underwriting year" detail={data.insuranceYear.toString()} />
          <DescriptionItem className={styles.item} term="Company name" detail={data.companyName} />
          <DescriptionItem className={styles.item} term="Company number" detail={data.companyNumber.toString()} />
          <DescriptionItem className={styles.item} term="Business account" detail={data.businessAccount} />
          <DescriptionItem className={styles.item} term="Business account number" detail={data.businessAccountNumber.toString()} />
        </dl>
      );
    default:
      return (
        <div className={styles.loader}>
          <Loader />
        </div>
      );
  }
};

// eslint-disable-next-line react/prop-types
const CoverContext = ({ setContext }) => {
  const { riskNumber, coverNumber, insuranceYear } = useParams();
  const { isShowing, show, hide } = useDialog();
  const getCovers = useCallback(
    () => getCoverByRiskNumberCoverNumberAndInsuranceYear(riskNumber, coverNumber, insuranceYear),
    [coverNumber, insuranceYear, riskNumber]
  );
  const { getInsuranceContext, contentState, data, error } = useInsuranceContext(getCovers);

  const retry = () => {
    getInsuranceContext();
  };

  useEffect(() => {
    setContext(data);
  }, [data, setContext]);

  switch (contentState) {
    case XHR_STATUS.ERROR:
      return (
        <div className={styles.error}>
          {error}
          <IconButton
            data-test-id="5yG81TRyCS_kRqeL-8-lp"
            className={styles.action}
            icon={mdiReload}
            text="Retry"
            iconSize={constants.SMALL_ICON_SIZE}
            onClick={retry}
          />
        </div>
      );
    case XHR_STATUS.SUCCESS:
      return (
        <>
          <dl className={styles.context}>
            <DescriptionItem
              className={styles.item}
              term="Interest code"
              detail={data.interest ? data.interest.shortName || data.interest.name : '—'}
            />
            <DescriptionItem className={styles.item} term="Risk number" detail={data.riskNumber.toString()} />
            <DescriptionItem className={styles.item} term="Cover note" detail={data.coverNote.toString()} />
            <DescriptionItem className={styles.item} term="Fleet name" detail={data.fleetName} />
            <DescriptionItem className={styles.item} term="Client" detail={data.client} />
            <DescriptionItem className={styles.item} term="Insurance period" detail={getFormatedPeriod(data.insurancePeriod)} />
          </dl>
          <IconButton
            data-test-id="0JrfXoULgbWZtmX8qSknE"
            className={styles.action}
            icon={mdiInformationOutline}
            iconSize={constants.SMALL_ICON_SIZE}
            onClick={show}
          />

          <ItsDialog isOpen={isShowing} onDismiss={hide} aria-label="Cover context information">
            <DialogHeader title="Cover context information" close={hide} />

            <CoverInfo context={data} />
          </ItsDialog>
        </>
      );
    default:
      return (
        <div className={styles.loader}>
          <Loader />
        </div>
      );
  }
};

// eslint-disable-next-line react/prop-types
const ClaimContext = ({ setContext }) => {
  const linkToCoverNoteDocsIsEnabled = useFeatureFlag(FeatureEnum.LINKTOCOVERNOTEDOCS);
  const { claimNumber, insuranceYear } = useParams();
  const { isShowing, show, hide } = useDialog();
  const getClaims = useCallback(() => getClaimByClaimNumberAndInsuranceYear(claimNumber, insuranceYear), [claimNumber, insuranceYear]);
  const { getInsuranceContext, contentState, data, error } = useInsuranceContext(getClaims);
  const coverNote = data?.coverNote;
  const riskNumber = data?.riskNumber ? data.riskNumber : 0;

  const pathnameCover = `/cases/${SearchContextType.COVER}/${riskNumber}/${coverNote}/${insuranceYear}`;

  const history = useHistory();

  const onCoverNoteClick = () => {
    if (pathnameCover !== history.location.pathnameCover) {
      history.push(pathnameCover);
    }
  };

  const retry = () => {
    getInsuranceContext();
  };

  useEffect(() => {
    setContext(data);
  }, [data, setContext]);

  switch (contentState) {
    case XHR_STATUS.ERROR:
      return (
        <div className={styles.error}>
          {error}
          <IconButton
            data-test-id="x1XpeCcZS2WsD9ZBsZwDD"
            className={styles.action}
            icon={mdiReload}
            text="Retry"
            iconSize={constants.SMALL_ICON_SIZE}
            onClick={retry}
          />
        </div>
      );
    case XHR_STATUS.SUCCESS:
      return (
        <>
          <dl className={styles.context}>
            <DescriptionItem className={styles.item} term="Vessel" detail={data.vesselName} />
            <DescriptionItem className={styles.item} term="Date of loss" detail={getFormatedDate(data.date)} />
            <DescriptionItem className={styles.item} term="Claim number" detail={`${data.claimNumber || '—'}  / ${data.insuranceYear || '—'}`} />
            {linkToCoverNoteDocsIsEnabled && (
              <DescriptionItem
                data-test-id="mNdQmamU7KJn9p77amrJr"
                className={styles.item}
                term="Cover Note"
                detail={data.coverNote || '—'}
                onClick={coverNote !== 0 ? onCoverNoteClick : null}
              />
            )}
            <DescriptionItem className={styles.item} term="Interest" detail={data.interest ? data.interest.shortName || data.interest.name : '—'} />
            <DescriptionItem className={styles.item} term="PETI" detail={data.peti} />
          </dl>
          <IconButton
            data-test-id="s5dNpXbWtXebAy03eLqeS"
            className={styles.action}
            icon={mdiInformationOutline}
            iconSize={constants.SMALL_ICON_SIZE}
            onClick={show}
          />

          <ItsDialog isOpen={isShowing} onDismiss={hide} aria-label="Claim context information">
            <DialogHeader title="Claim Information" close={hide} />

            <ClaimInfo context={data} />
          </ItsDialog>
        </>
      );
    default:
      return (
        <div className={styles.loader}>
          <Loader />
        </div>
      );
  }
};

const useInsuranceContext = (fetchCallback) => {
  const [contentState, setContentState] = useState(XHR_STATUS.INITIAL);
  const [data, setData] = useState(null);
  const [error, setError] = useState();

  const getInsuranceContext = useCallback(async () => {
    setContentState(XHR_STATUS.LOADING);
    try {
      const result = await fetchCallback();
      setData(result);
      setContentState(XHR_STATUS.SUCCESS);
    } catch (_) {
      setError('Unable to load insurance context');
      setContentState(XHR_STATUS.ERROR);
    }
  }, [fetchCallback]);

  useEffect(() => {
    getInsuranceContext();
  }, [getInsuranceContext]);

  return { getInsuranceContext, contentState, data, error };
};
