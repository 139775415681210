import React from 'react';
import PdfViewer from './PdfViewer/PdfViewer';
import style from './FileViewer.module.scss';
import { EmailLoader } from './EmailViewer';
import { FeatureEnum, useFeatureFlag } from '../../providers/FeatureFlagProvider';
import { useFile, useImageUrl } from '../../hooks/documents';
import PageSpinner from './Spinner';
import { PreformattedText } from './PreformattedText';
import { ScrollContainer } from './ScrollContainer';

const FileType = {
  UNSUPPORTED: 0,
  DOC: 1,
  IMAGE: 2,
  TEXT: 3,
  EMAIL: 4,
  PDF: 5,
  PPT: 6,
};
const imagesExtensions = ['.png', '.jpg', '.jpeg', '.bmp', '.tif', '.tiff', '.gif'];
const documentExtensions = ['.doc', '.docm', '.docx', '.xls', '.xlsb', '.xlsm', '.xlsx', '.rtf', '.ppt', '.pptx'];
const emailExtensions = ['.msg', '.eml'];
const pdfExtensions = ['.pdf'];
const textExtensions = ['.txt', '.csv'];

const getFileType = (name: string) => {
  if (textExtensions.some((x) => name.endsWith(x))) return FileType.TEXT;
  if (imagesExtensions.some((x) => name.endsWith(x))) return FileType.IMAGE;
  if (documentExtensions.some((x) => name.endsWith(x))) return FileType.DOC;
  if (emailExtensions.some((x) => name.endsWith(x))) return FileType.EMAIL;
  if (pdfExtensions.some((x) => name.endsWith(x))) return FileType.PDF;
  return FileType.UNSUPPORTED;
};

interface ImageViewerProps {
  fileId: string;
  alt: string;
}

const ImageViewer = ({ fileId, alt }: ImageViewerProps) => {
  const { data, isLoading, error } = useImageUrl(fileId);

  if (isLoading) return <PageSpinner className={style.loader} />;
  if (error) return <div>Unexpected error</div>;
  return <img className={style.image} alt={alt} src={data} />;
};

interface DocumentViewerProps {
  fileId: string;
  isSinglePageViewer: boolean;
  doOcr: boolean;
  asBytes: boolean;
  openInModal: boolean;
}

const DocumentViewer = ({ fileId, isSinglePageViewer, doOcr = false, asBytes = true, openInModal }: DocumentViewerProps) => {
  const { data, isLoading, error } = useFile(fileId, asBytes);

  if (isLoading) return <PageSpinner className={style.loader} />;
  if (error) return <div>Unexpected error</div>;
  if (asBytes) return <PdfViewer src={data} fileId={doOcr ? fileId : undefined} isSinglePageViewer={isSinglePageViewer} />;

  if (openInModal) {
    return (
      <ScrollContainer>
        <PreformattedText>{data}</PreformattedText>
      </ScrollContainer>
    );
  }
  return <PreformattedText>{data}</PreformattedText>;
};

type File = { id: string; name: string };

interface FileViewerProps {
  file: File;
  openInModal: boolean;
}

const FileViewer = ({ file: { id, name }, openInModal }: FileViewerProps) => {
  const enableDocumentPreviewSearch = useFeatureFlag(FeatureEnum.PDFSEARCH);
  const fileType = getFileType(name.toLowerCase());
  const doOcr = fileType === FileType.PDF;
  const asBytes = fileType === FileType.DOC || fileType === FileType.PDF;

  if (fileType === FileType.EMAIL) return <EmailLoader id={id} name={name} />;

  if (fileType === FileType.DOC || fileType === FileType.PDF || fileType === FileType.TEXT)
    return (
      <DocumentViewer
        fileId={id}
        isSinglePageViewer={!openInModal || !enableDocumentPreviewSearch}
        doOcr={doOcr}
        asBytes={asBytes}
        openInModal={openInModal}
      />
    );

  if (fileType === FileType.IMAGE) return <ImageViewer fileId={id} alt={name} />;

  return <span>Unsupported file type.</span>;
};

export default FileViewer;
